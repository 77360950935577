<template>
  <div class="list">
    <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
    <global-table
      ref="zllist"
      :tableField="tableField"
      @tableAction="tableAction"
      :tableData="tableData"
      :paginationData="paginationData"
      @pageChange="pageChange"
    ></global-table>
    <dialog-box
      ref="addDialog"
      dialogWidth="820px"
      :dialogShow="dialogShow"
      @handleClose="handleClose"
      @confirmAdd="confirmAdd"
      title="上传质量体系资质附件"
      componentName="AddFileList"
      :buttonData="[
        { label: '取消', action: 'handleClose', size: 'mini' },
        { label: '确认', action: 'confirmAdd', type: 'primary', size: 'mini' },
      ]"
    >
    </dialog-box>
    <dialog-box
      ref="addDialog"
      dialogWidth="820px"
      :formData1="showFormData1"
      :formData="showFormData"
      :dialogShow="showDialogShow"
      @handleClose="handleClose"
      @confirmAdd="handleClose"
      title="查看附件"
      componentName="ShowFileList"
      :buttonData="[
        { label: '取消', action: 'handleClose', size: 'mini' },
        { label: '确认', action: 'confirmAdd', type: 'primary', size: 'mini' },
      ]"
    >
    </dialog-box>
  </div>
</template>

<script>
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import Component from '@/assets/js/components.js'
import { tableField, tableData } from './js/setting.js'
import { request } from '@/assets/js/http.js'
export default {
  components: { ...Component.components, GlobalTable },
  name: 'AdviseList',
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      showFormData: {},
      showFormData1: {},
      paginationData: {
        pageSize: 0,
        total: 0
      },
      dialogShow: false,
      showDialogShow: false,
      pageNum: 1,
      menudata: this.$store.state.ViewMenuData.menuData
    }
  },
  created () {
    this.myBidData()
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    }
  },
  methods: {
    handleClose () {
      this.dialogShow = false
      this.showDialogShow = false
    },
    confirmAdd () {
      let pass = true
      this.$refs.addDialog.$refs.content.$refs.addfilelidt.validate((valid) => {
        if (!valid) {
          pass = false
        }
      })
      if (!pass) {
        this.$message({
          message: '请填写完整！',
          type: 'error'
        })
        return false
      }
      var formData = this.$refs.addDialog.$refs.content.form
      this.$confirm('是否提交？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          request('/api/quality/data/save', 'post', formData).then((res) => {
            if (res.code === '200') {
              this.$message({
                message: '添加成功',
                type: 'success'
              })
              this.handleClose()
              this.myBidData()
            }
          })
        })
        .catch(() => {
          this.$message({
            message: '操作已取消！',
            type: 'info'
          })
        })
    },
    buttonClick: function (data) {
      if (data.alias === 'DeleteQualityData') {
        const dellist = []
        for (const item of this.$refs.zllist.selectData) {
          dellist.push(item.id)
        }
        this.$confirm('是否删除？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            request('/api/quality/data/delete', 'delete', dellist).then(
              (res) => {
                if (res.code === '200') {
                  this.$message({
                    message: '删除成功',
                    type: 'success'
                  })
                  this.myBidData()
                }
              }
            )
          })
          .catch(() => {
            this.$message({
              message: '操作已取消！',
              type: 'info'
            })
          })
      } else {
        this.dialogShow = true
      }
    },
    myBidData () {
      var pageSize = parseInt(
        (document.documentElement.clientHeight - 330) / 42
      )
      if (pageSize < 10) {
        pageSize = 10
      }
      request(
        '/api/quality/data/list?' +
          'pageNum=' +
          this.pageNum +
          '&' +
          'pageSize=' +
          pageSize,
        'GET'
      ).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          if (this.paginationData.total === 0) {
            this.paginationData = { pageSize: pageSize, total: res.data.total }
          } else {
            this.paginationData.total = res.data.total
          }
        }
      })
    },
    tableAction: function (index, data, clickBtn) {
      request(`/api/quality/data/getById/${data.id}`, 'get').then((res) => {
        if (res.code === '200') {
          this.showFormData = res.data
          this.showFormData1 = data
          this.showDialogShow = true
        }
      })
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
